import React from 'react';
import DropdownButton from './DropdownButton';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <header className="w-full flex flex-row items-center justify-between bg-darkslategray text-white py-2 font-gothic">
        <div className="flex items-center">
            {/* Oliveロゴ */}
            <a 
            href="/" 
            className="text-11xl font-bold ml-8 mr-16 no-underline text-white hover:text-gray-300 transition-colors duration-300">
            {t("olive")}
            </a>
            {/* SNSアイコンと言語切り替え */}
            <div className="flex items-center space-x-6 pt-[0.5rem] font-gothic text-5xl">
                <a href="https://www.facebook.com/OliveWeSenseYou" className="hover:opacity-80" target="_blank" rel="noopener noreferrer">
                <img
                    className="w-6 h-6"
                    loading="lazy"
                    alt="Facebook"
                    src="/facebook.svg"
                />
                </a>
                <a href="https://www.instagram.com/olivewesenseyou/" className="hover:opacity-80" target="_blank" rel="noopener noreferrer">
                <img
                    className="w-6 h-6"
                    loading="lazy"
                    alt="Instagram"
                    src="/instagram.svg"
                />
                </a>
                <a href="https://x.com/OliveWeSenseYou" className="hover:opacity-80" target="_blank" rel="noopener noreferrer">
                <img
                    className="w-6 h-6"
                    loading="lazy"
                    alt="Twitter"
                    src="/twitter.svg"
                />
                </a>
                <div className="ml-6">
                    <LanguageSwitcher />
                </div>
            </div>
        </div>
        <DropdownButton />
    </header>
  );
};

export default Header;