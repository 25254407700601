import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

const Company: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const officeMapSrc = i18n.language === 'en' ? '/officemap_en.svg' : '/officemap.svg';

  return (
    <div className="w-full flex flex-col min-h-screen">
      <main className="max-w-4xl mx-auto px-5 py-10">
        <section className="text-black font-gothic">
          <div className="text-center text-5xl text-center mb-6 font-bold">
          {t("company")}
          </div>
          <div className="bg-white pl-20 pr-10 py-10 rounded-xl border-2 border-black border-solid !border-opacity-100 font-kosugi">
            <div className="flex flex-col-reverse md:flex-row">
              <div className="w-full md:w-1/3 mt-6 md:mt-0">
                <h4 className="font-bold mb-2">{t("name")}</h4>
                <p className="my-1">{t("corp.")}</p>
                <h4 className="font-bold mt-6 mb-2">{t("established")}</h4>
                <p className="my-1">{t("date1")}</p>
                <p className="my-1">{t("date2")}</p>
                <h4 className="font-bold mt-6 mb-2">{t("capital")}</h4>
                <p className="my-1">{t("money")}</p>
                <h4 className="font-bold mt-6 mb-2">{t("size")}</h4>
                <p className="my-1">{t("member")}</p>
                <p className="my-1">{t("phd")}</p>
                <p className="my-1">{t("foreignnationals")}</p>
                <h4 className="font-bold mt-6 mb-2">{t("bank")}</h4>
                <p className="my-1">
                  {t("nagoya")}<br />
                  {t("ogaki")}<br />
                  {t("ufj")}
                </p>
              </div>

              <div className="w-full md:w-2/3 mx-auto my-auto">
                <img src={officeMapSrc} alt={t("companyLogo")} className="w-full h-auto" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Company;