import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AboutOlive() {
  const [imageError, setImageError] = React.useState(false);
  const { t, i18n } = useTranslation();

  const getLocalizedUrl = (jaUrl: string, enUrl: string) => {
    return i18n.language === 'ja' ? jaUrl : enUrl;
  };

  const items = [
    { text: t("company2"), angle: 0, href: '/company' },
    { text: t("aboutus"), angle: 45, href: '/aboutUs' },
    { text: t("developer"), angle: 90, href: '/story' },
    { text: t("news"), angle: 135, href: '/pressrelease' },
    { text: t("careers"), angle: 180, href: '/recruit' },
    { text: t("map"), angle: 225, href: getLocalizedUrl('https://map.01ive.co.jp', 'https://map.01ive.co.jp'), external: true },
    { text: t("contact"), angle: 270, href: '/contact' },
    { text: t("secret"), angle: 315, href: getLocalizedUrl('https://lite.demo.01ive.co.jp', 'https://lite-en.demo.01ive.co.jp/'), external: true },
  ];

  const radius = 85;
  const textRadius = 50;

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error("画像の読み込みエラー", e);
    setImageError(true);
  };

  const centerImage = imageError ? (
    <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-600">
      画像が利用できません
    </div>
  ) : (
    <div className="w-full flex justify-center">
      <img 
        src="/Centerimage.svg"  // process.env.PUBLIC_URLを削除
        alt="中央画像"
        className="h-auto w-full max-w-[120px] sm:max-w-[150px] md:max-w-[181px]" 
        onError={handleImageError}
        loading="lazy"
      />
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen font-gothic">
      <main className="flex-grow">
        <div className="relative w-full max-w-[600px] mx-auto my-8">
          {/* スマートフォン用のレイアウト */}
          <div className="md:hidden flex flex-col items-center">
            <div className="w-full flex justify-center mb-8">
              {centerImage}
            </div>
            {items.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="mb-4 no-underline text-black text-center"
                {...(item.external ? { target: "_blank", rel: "noopener noreferrer" } : {})}
              >
                <span className="text-base">{item.text}</span>
              </a>
            ))}
          </div>

          {/* デスクトップ用のレイアウト */}
          <div className="hidden md:block aspect-square">
            <svg className="w-full h-full" viewBox="0 0 200 200">
              {items.map((item, index) => (
                <line
                  key={index}
                  x1="100"
                  y1="100"
                  x2={100 + radius * Math.cos((item.angle * Math.PI) / 180)}
                  y2={100 + radius * Math.sin((item.angle * Math.PI) / 180)}
                  stroke="black"
                  strokeWidth="1"
                  className="origin-center"
                />
              ))}
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              {centerImage}
            </div>
            {items.map((item, index) => {
              const angle = (item.angle * Math.PI) / 180;
              const x = 50 + textRadius * Math.cos(angle);
              const y = 50 + textRadius * Math.sin(angle);
              return (
                <a
                  key={index}
                  href={item.href}
                  className="absolute flex items-center no-underline text-black whitespace-nowrap"
                  style={{
                    left: `${x}%`,
                    top: `${y}%`,
                    transform: `translate(-50%, -50%)`,
                  }}
                  {...(item.external ? { target: "_blank", rel: "noopener noreferrer" } : {})}
                >
                  <span className="text-sm md:text-base lg:text-lg xl:text-xl">{item.text}</span>
                </a>
              );
            })}
          </div>
        </div>
      </main>
    </div>
  );
}